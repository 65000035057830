import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const NoPage = () => {
  return <div>404 Error</div>;
};

const NewAboutComponent = MaxWidthHocWrapper(NoPage);

export default NewAboutComponent;
