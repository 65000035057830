import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const Careers = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-blue-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-indigo-600 mb-6">
            Join Our Team at Trip Closure
          </h1>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-8">
            At Trip Closure, we are always looking for passionate individuals to
            join our team and help us create exceptional travel experiences.
          </p>
        </div>

        <div className="mt-10">
          <div className="text-center">
            <h3 className="text-2xl font-medium text-indigo-500 mb-4">
              Current Openings
            </h3>
            <p className="text-base text-gray-600 mb-6">
              We currently have no open positions. However, we are always
              interested in hearing from talented individuals who share our
              passion for travel. Please keep checking our LinkedIn page for the
              latest job openings.
            </p>
            <a
              href="https://www.linkedin.com/company/tripclosure"
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 hover:text-indigo-500 font-semibold"
            >
              Visit our LinkedIn Page
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewCareersComponent = MaxWidthHocWrapper(Careers);

export default NewCareersComponent;
