import React, { useCallback, useEffect, useState } from "react";
// import EffectsCardCarousel from "../../components/Effects Card Carousel/EffectsCardCarousel.js";
import NormalCarousel from "../../components/Normal Carousel/NormalCarousel.js";
import WhatWeOffer from "./WhatWeOffer.jsx";
import OurClients from "./OurClients.jsx";
import { ApiHandlerGet } from "../../utils/ApiHandler.js";
import { unsplash_url } from "../../utils/urls.js";
import { exampleImagesData } from "../../utils/constant.js";

const Home = () => {
  const [images, setImages] = useState(exampleImagesData ?? []);

  const searchPhotos = useCallback(async (query, page = 1, perPage = 5) => {
    try {
      const res = await ApiHandlerGet(unsplash_url, {
        query,
        page,
        perPage,
      });

      setImages(res?.results);
    } catch (error) {
      console.error("Failed to fetch images from Unsplash:", error);
    }
  }, []);

  useEffect(() => {
    searchPhotos("travel");
  }, [searchPhotos]);

  return (
    <div>
      <NormalCarousel images={images} />
      <div className="flex flex-col p-5 md:p-5 md:flex-row justify-evenly items-center">
        <div className="text-center my-10 w-[100%] md:w-[50%]">
          <div className="mb-2 text-3xl md:text-4xl font-libreCaslonDisplay">
            Welcome To Trip Closure
          </div>
          <div className="text-lg sm:text-xl md:text-2xl font-caveat">
            "Where your unfogettable journeys begins and wanderlust finds its
            perfect match"
          </div>
        </div>
        {/* <EffectsCardCarousel images={images} /> */}
      </div>
      <WhatWeOffer />
      <OurClients />
    </div>
  );
};

export default Home;
