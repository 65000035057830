import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const TermsConditions = () => {
  return (
    <div className="bg-gray-50 py-10">
      <div className="container mx-auto p-4 bg-white shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Terms & Conditions
        </h1>
        <div className="text-gray-700 text-sm sm:text-base leading-relaxed">
          <p className="mb-4">
            Welcome to Trip Closure. By accessing and using our website, you
            agree to comply with and be bound by the following terms and
            conditions.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
          <p className="mb-4">
            These terms and conditions govern your use of this website; by using
            this website, you accept these terms and conditions in full.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            2. Intellectual Property Rights
          </h2>
          <p className="mb-4">
            Unless otherwise stated, Trip Closure and/or its licensors own the
            intellectual property rights published on this website and materials
            used on Trip Closure.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            3. License to Use Website
          </h2>
          <p className="mb-4">
            You may view, download for caching purposes only, and print pages
            from the website for your own personal use, subject to the
            restrictions set out below and elsewhere in these terms and
            conditions.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">4. User Conduct</h2>
          <p className="mb-4">
            You must not use this website in any way that causes, or may cause,
            damage to the website or impairment of the availability or
            accessibility of the website.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            5. Limitation of Liability
          </h2>
          <p className="mb-4">
            Trip Closure will not be liable to you in relation to the contents
            of, or use of, or otherwise in connection with, this website.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">6. Variation</h2>
          <p className="mb-4">
            Trip Closure may revise these terms and conditions from time to
            time. Revised terms and conditions will apply to the use of this
            website from the date of the publication of the revised terms and
            conditions on this website.
          </p>
          <p className="mt-8 text-center">
            If you have any questions about these Terms & Conditions, please
            contact us at info@tripclosure.com.
          </p>
        </div>
      </div>
    </div>
  );
};

const NewTermsComponent = MaxWidthHocWrapper(TermsConditions);

export default NewTermsComponent;
