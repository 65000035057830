import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { navigation } from "../utils/constant";
import tripclosure_logo from "../static/tripclosure_logo.png";

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white" data-testid="header">
      <nav
        className="max-w-screen-2xl mx-auto flex items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 items-center">
          <Link to="/">
            <img
              className="h-10 md:h-12 lg:h-16 w-auto ml-1"
              src={tripclosure_logo}
              alt="Trip Closure Logo"
            />
            {/* <span className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-orange-400">
              Trip Closure
            </span> */}
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            {/* <span className="sr-only">Open main menu</span> */}
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => {
            return (
              <Link
                to={item.path}
                key={item.id}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                {item.title}
              </Link>
            );
          })}
        </Popover.Group>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-4 lg:px-8 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div className="flex lg:flex-1 items-center">
              <Link to="/" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <img
                  className="h-10 md:h-12 lg:h-16 w-auto ml-1"
                  src={tripclosure_logo}
                  alt="Trip Closure Logo"
                />
                {/* <span className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-orange-400">
                  Trip Closure
                </span> */}
              </Link>
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flex justify-center text-center">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => {
                  return (
                    <Link
                      to={item.path}
                      key={item.id}
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                      className="-mx-3 block rounded-lg py-2 px-3 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
