import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Navigation, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

import image1 from "../../static/images/1.jpeg";
import image2 from "../../static/images/2.jpeg";
import image3 from "../../static/images/3.jpeg";
import image4 from "../../static/images/4.jpg";
import image5 from "../../static/images/5.jpg";
import image6 from "../../static/images/6.jpg";
import image7 from "../../static/images/7.jpg";
import image8 from "../../static/images/8.jpg";
import image9 from "../../static/images/9.jpg";
import image10 from "../../static/images/10.jpg";

const CarouselImages = [
  {
    id: 1,
    img: image1,
    alt_description: "image 1",
  },
  {
    id: 2,
    img: image2,
    alt_description: "image 2",
  },
  {
    id: 3,
    img: image3,
    alt_description: "image 3",
  },
  {
    id: 4,
    img: image4,
    alt_description: "image 4",
  },
  {
    id: 5,
    img: image5,
    alt_description: "image 5",
  },
  {
    id: 6,
    img: image6,
    alt_description: "image 6",
  },
  {
    id: 7,
    img: image7,
    alt_description: "image 7",
  },
  {
    id: 8,
    img: image8,
    alt_description: "image 8",
  },
  {
    id: 9,
    img: image9,
    alt_description: "image 9",
  },
  {
    id: 10,
    img: image10,
    alt_description: "image 10",
  },
];

const NormalCarousel = ({ images }) => {
  return (
    <Swiper
      modules={[Navigation, Keyboard, Autoplay, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      keyboard={{
        enabled: true,
      }}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
      className="w-full"
    >
      {CarouselImages?.map((image) => (
        <SwiperSlide
          className="flex items-center justify-center p-0 m-0 overflow-hidden"
          key={image?.id}
        >
          <img
            src={image?.img}
            alt={image?.alt_description}
            className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover"
          />
        </SwiperSlide>
      ))}
      {/* {images?.map((image, idx) => (
        <SwiperSlide
          className="flex items-center justify-center p-0 m-0 overflow-hidden"
          key={idx}
        >
          <img
            src={image?.urls?.raw}
            alt={image?.alt_description}
            className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover"
          />
        </SwiperSlide>
      ))} */}
    </Swiper>
  );
};

export default NormalCarousel;
