import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const Packages = () => {
  return (
    <div className="App">
      <div>
        Hey there! This page is currently under maintenance. It will be updated
        soon.
      </div>
    </div>
  );
};

const NewAboutComponent = MaxWidthHocWrapper(Packages);

export default NewAboutComponent;
