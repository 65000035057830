import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const ContactUs = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-blue-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-indigo-600 mb-6">
            Get in Touch with Trip Closure
          </h1>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-8">
            Have questions or need assistance? We're here to help you with all
            your travel needs.
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-1 gap-8">
          {/* Contact Information */}
          <div className="text-left bg-white p-6 shadow-lg rounded-lg">
            <h3 className="text-2xl font-medium text-indigo-500 mb-4">
              Contact Information
            </h3>
            <p className="text-base text-gray-600 mb-6">
              You can reach us through the following methods:
            </p>
            <ul className="text-base text-gray-600 space-y-4">
              <li>
                <strong>Email:</strong>{" "}
                <a
                  href="mailto:booking@tripclosure.com"
                  className="text-indigo-600 hover:underline"
                >
                  booking@tripclosure.com
                </a>
              </li>
              <li>
                <strong>Phone:</strong>{" "}
                <span>
                  <a
                    href="tel:+919810180806"
                    className="text-indigo-600 hover:underline"
                  >
                    +91 9810180806
                  </a>
                  <span className="ml-2 mr-2">|</span>
                  <a
                    href="tel:+919873280806"
                    className="text-indigo-600 hover:underline"
                  >
                    +91 9873280806
                  </a>
                </span>
              </li>
              <li>
                <strong>Address:</strong>{" "}
                <span className="text-gray-600">
                  198, West Azad Nagar, Delhi - 110051
                </span>
              </li>
              <li>
                <strong>Working Hours:</strong>{" "}
                <span className="text-gray-600">24*7 (365 Days)</span>
              </li>
            </ul>
          </div>

          {/* <div className="bg-white p-6 shadow-lg rounded-lg">
            <h3 className="text-lg leading-6 font-medium text-indigo-900 mb-4">
              Send Us a Message
            </h3>
            <form action="#" method="POST">
              <div className="grid grid-cols-1 gap-y-6">
                <div>
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    required
                    className="block w-full px-4 py-3 text-base text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Your Name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    className="block w-full px-4 py-3 text-base text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Your Email"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    required
                    className="block w-full px-4 py-3 text-base text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center py-3 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const NewContactUsComponent = MaxWidthHocWrapper(ContactUs);

export default NewContactUsComponent;
