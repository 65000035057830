import React from "react";
import { Link } from "react-router-dom";
import { navigation } from "../utils/constant";

const socialHandles = [
  {
    key: 1,
    socialPlatform: "instagram",
    url: "https://www.instagram.com/tripclosure",
  },
  { key: 2, socialPlatform: "twitter", url: "https://twitter.com/tripclosure" },
  {
    key: 4,
    socialPlatform: "facebook",
    url: "https://www.facebook.com/Tripclosure/",
  },
];

const legalNavigation = [
  {
    id: 1,
    title: "Privacy Policy",
    path: "privacypolicy",
  },
  {
    id: 2,
    title: "Terms & Conditions",
    path: "termsconditions",
  },
];

const Footer = () => {
  return (
    <footer className="bg-gray-900">
      <div className="max-w-screen-2xl mx-auto w-full p-4 py-6 lg:px-8 lg:py-8">
        <div className="text-center md:flex md:justify-evenly grid grid-cols-1 md:grid-cols-3 gap-8 sm:gap-6">
          <div>
            <h2 className="mb-2 text-lg font-semibold text-gray-900 uppercase dark:text-white">
              Navigation
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {navigation.map((item) => {
                return (
                  <li className="leading-10" key={item.id}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2 className="mb-2 text-lg font-semibold text-gray-900 uppercase dark:text-white">
              Follow us
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {socialHandles.map((account) => {
                return (
                  <li className="leading-10" key={account.key}>
                    <a href={account.url} target="_blank" rel="noreferrer">
                      <span
                        className={`mr-2 fa fa-${account.socialPlatform}`}
                      ></span>
                      <span className="capitalize">
                        {account.socialPlatform}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2 className="mb-4 text-lg font-semibold text-gray-900 uppercase dark:text-white">
              Legal
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {legalNavigation.map((item) => {
                return (
                  <li className="mb-4 leading-6" key={item.id}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="text-gray-400 mb-4 text-center">
          © 2021 Trip Closure · All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
