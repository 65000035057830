import React from "react";

export const MaxWidthHocWrapper = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <div>
          <div className="max-w-screen-2xl mx-auto p-2 lg:px-4">
            <WrappedComponent />
          </div>
        </div>
      );
    }
  }
  return HOC;
};
