import React from "react";
import { MaxWidthHocWrapper } from "../../components/MaxWidthHocWrapper";

const services = [
  {
    title: "Corporate Travel",
    description:
      "We understand the needs of corporate clients and provide tailored solutions to ensure smooth and efficient business travel.",
  },
  {
    title: "Air Ticketing",
    description:
      "Whether you're flying for business or leisure, our team ensures you get the best deals on air tickets, with a seamless booking experience.",
  },
  {
    title: "Tour Packages",
    description:
      "Explore our carefully crafted tour packages that offer a perfect blend of adventure, culture, and relaxation, tailored to meet diverse preferences.",
  },
  {
    title: "Hotel Bookings",
    description:
      "We partner with top hotels worldwide to offer you comfortable stays, whether you're looking for luxury or budget accommodations.",
  },
  {
    title: "Transportation",
    description:
      "From airport transfers to city tours, we provide reliable transportation services to ensure your journey is hassle-free from start to finish.",
  },
  {
    title: "Travel Insurance",
    description:
      "Travel with peace of mind by availing our comprehensive travel insurance plans that cover unexpected events during your trip.",
  },
];

function WhatWeOffer() {
  return (
    <div className="container mx-auto p-6 bg-gradient-to-b from-gray-50 to-gray-100 rounded-xl shadow-lg">
      <h1 className="font-bold text-gray-800 text-3xl text-center font-libreCaslonDisplay mb-8">
        What We Offer
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 hover:bg-gray-50 transition duration-300 ease-in-out transform hover:scale-105"
          >
            <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
              {service.title}
            </h3>
            <p className="text-gray-600 text-base">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const NewServicesComponent = MaxWidthHocWrapper(WhatWeOffer);

export default NewServicesComponent;
