import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const About = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-blue-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-indigo-600 mb-6">
            Your Trusted Travel Partner
          </h1>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-8">
            At Trip Closure, we specialize in creating seamless and memorable
            travel experiences for all our clients.
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Section 1: Corporate Travel */}
            <div className="bg-white p-6 shadow-lg rounded-lg">
              <h3 className="text-2xl font-medium text-indigo-500">
                Corporate Travel
              </h3>
              <p className="text-base text-gray-600 mt-2">
                We understand the needs of corporate clients and provide
                tailored solutions to ensure smooth and efficient business
                travel.
              </p>
            </div>

            {/* Section 2: Air Ticketing */}
            <div className="bg-white p-6 shadow-lg rounded-lg">
              <h3 className="text-2xl font-medium text-indigo-500">
                Air Ticketing
              </h3>
              <p className="text-base text-gray-600 mt-2">
                Whether you're flying for business or leisure, our team ensures
                you get the best deals on air tickets, with a seamless booking
                experience.
              </p>
            </div>

            {/* Section 3: Tour Packages */}
            <div className="bg-white p-6 shadow-lg rounded-lg">
              <h3 className="text-2xl font-medium text-indigo-500">
                Tour Packages
              </h3>
              <p className="text-base text-gray-600 mt-2">
                Explore our carefully crafted tour packages that offer a perfect
                blend of adventure, culture, and relaxation, tailored to meet
                diverse preferences.
              </p>
            </div>

            {/* Section 4: Hotel Bookings */}
            <div className="bg-white p-6 shadow-lg rounded-lg">
              <h3 className="text-2xl font-medium text-indigo-500">
                Hotel Bookings
              </h3>
              <p className="text-base text-gray-600 mt-2">
                We partner with top hotels worldwide to offer you comfortable
                stays, whether you're looking for luxury or budget
                accommodations.
              </p>
            </div>

            {/* Section 5: Transportation */}
            <div className="bg-white p-6 shadow-lg rounded-lg">
              <h3 className="text-2xl font-medium text-indigo-500">
                Transportation
              </h3>
              <p className="text-base text-gray-600 mt-2">
                From airport transfers to city tours, we provide reliable
                transportation services to ensure your journey is hassle-free
                from start to finish.
              </p>
            </div>

            {/* Section 6: Travel Insurance */}
            <div className="bg-white p-6 shadow-lg rounded-lg">
              <h3 className="text-2xl font-medium text-indigo-500">
                Travel Insurance
              </h3>
              <p className="text-base text-gray-600 mt-2">
                Travel with peace of mind by availing our comprehensive travel
                insurance plans that cover unexpected events during your trip.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewAboutComponent = MaxWidthHocWrapper(About);

export default NewAboutComponent;
