import axios from "axios";

export const ApiHandlerGet = async (url, params) => {
  try {
    const { query = "", page = 1, perPage = 1 } = params;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`,
      },
      params: {
        query,
        page,
        per_page: perPage,
      },
    });
    return res.data;
  } catch (error) {
    console.log("An Error occurred while fetching data: ", error);
  }
};
