import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import Packages from "./pages/Packages";
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import TermsCondition from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <>
      {/* <div className="App">
        <div>
          Hey there! <span className="title">tripclosure.com</span> is currently
          under maintenance. We will be live soon.
        </div>
      </div> */}
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="packages" element={<Packages />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="career" element={<Career />} />
            <Route path="services" element={<Services />} />
            <Route path="contactUs" element={<Contact />} />
            <Route path="termsconditions" element={<TermsCondition />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
