import React from "react";
import Marquee from "react-fast-marquee";
import jdd_client_logo from "../../static/jdd_logo.jpg";
import sse_client_logo from "../../static/sse_logo.jpg";
import abson_technologies_client_logo from "../../static/abson_technologies_logo.png";
import ovize_client_logo from "../../static/ovize_logo.png";
import next_reality_client_logo from "../../static/next_reality_logo.jpg";
import sts_logo from "../../static/sts_logo.png";

const clientLogos = [
  {
    name: "Jain Drug Distributer",
    logo: jdd_client_logo,
  },
  {
    name: "Shyam Stage Effects",
    logo: sse_client_logo,
  },
  {
    name: "Abson Technologies",
    logo: abson_technologies_client_logo,
  },
  {
    name: "Ovize",
    logo: ovize_client_logo,
  },
  {
    name: "Next Reality",
    logo: next_reality_client_logo,
  },
  {
    name: "Sampoorna Taxt Solutions",
    logo: sts_logo,
  },
];

function ClientLogos() {
  return (
    <div className="text-center py-10 px-2 mt-8 bg-gradient-to-b from-blue-50 to-blue-100">
      <h1 className="font-bold text-gray-800 text-2xl sm:text-3xl text-center font-libreCaslonDisplay mb-8">
        Our Clients
      </h1>
      <Marquee pauseOnHover={true} gradient={false} speed={50}>
        <div className="flex space-x-6 p-2">
          {clientLogos.map((client, index) => (
            <div
              key={index}
              className="flex items-center justify-center bg-white rounded-lg max-w-[180px] sm:max-w-[220px] mx-2 sm:mx-4 transform transition-transform hover:scale-105 hover:shadow-xl"
            >
              <img
                src={client.logo}
                alt={`${client.name} Logo`}
                className="h-16 sm:h-24 object-contain rounded-md"
              />
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
}

export default ClientLogos;
