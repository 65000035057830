import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const AboutUs = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-blue-100 text-gray-800 p-8 sm:p-16">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8 text-indigo-600">
          About Trip Closure
        </h1>
        <p className="text-lg mb-6">
          At Trip Closure, we believe in making every journey unforgettable.
          Whether it's corporate travel or a family vacation, our mission is to
          ensure that every aspect of your trip is smooth, enjoyable, and
          tailored to your needs. With a passion for exploration and a
          commitment to excellence, we have been helping travelers discover the
          world with ease and confidence.
        </p>

        <div className="my-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-500">
            Our Story
          </h2>
          <p className="text-lg mb-6">
            Founded by a group of travel enthusiasts, Trip Closure was born out
            of a desire to make travel accessible and hassle-free. We started as
            a small agency with big dreams, and over the years, we have grown
            into a trusted partner for thousands of travelers worldwide. Our
            journey has been fueled by a deep love for adventure and a
            dedication to providing top-notch service.
          </p>
        </div>

        <div className="my-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-500">
            What We Offer
          </h2>
          <ul className="list-disc list-inside space-y-4">
            <li className="text-lg">
              <strong>Corporate Travel:</strong> Tailored solutions for business
              travel that prioritize efficiency and comfort.
            </li>
            <li className="text-lg">
              <strong>Air Ticketing:</strong> Competitive prices and seamless
              booking experiences for all your flight needs.
            </li>
            <li className="text-lg">
              <strong>Tour Packages:</strong> Carefully curated packages that
              offer a perfect blend of adventure, culture, and relaxation.
            </li>
            <li className="text-lg">
              <strong>Hotel Bookings:</strong> Access to a wide range of
              accommodations, from luxury to budget, ensuring a perfect stay.
            </li>
            <li className="text-lg">
              <strong>Transportation:</strong> Reliable services to make your
              journey stress-free, from airport transfers to city tours.
            </li>
            <li className="text-lg">
              <strong>Travel Insurance:</strong> Comprehensive plans that give
              you peace of mind on your travels.
            </li>
          </ul>
        </div>

        <div className="my-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-500">
            Our Vision
          </h2>
          <p className="text-lg mb-6">
            Our vision is to become a leading global travel agency known for our
            exceptional service and commitment to making travel dreams come
            true. We aim to create memorable experiences for every traveler,
            whether they're exploring new cultures, closing business deals, or
            simply unwinding on a beach.
          </p>
        </div>

        <div className="my-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-500">
            Why Choose Us?
          </h2>
          <ul className="list-disc list-inside space-y-4">
            <li className="text-lg">
              <strong>Expertise:</strong> Our team is composed of seasoned
              travel experts who know the ins and outs of the industry.
            </li>
            <li className="text-lg">
              <strong>Personalized Service:</strong> We tailor our services to
              meet your specific travel needs and preferences.
            </li>
            <li className="text-lg">
              <strong>Global Reach:</strong> With partners and resources around
              the world, we can arrange travel to virtually any destination.
            </li>
            <li className="text-lg">
              <strong>Customer Satisfaction:</strong> We pride ourselves on
              delivering experiences that exceed our customers' expectations.
            </li>
          </ul>
        </div>

        <div className="my-12 text-center">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-500">
            Join Us on the Journey
          </h2>
          <p className="text-lg mb-6">
            At Trip Closure, we're more than just a travel agency – we're your
            partner in exploring the world. Whether you're traveling for
            business or pleasure, we’re here to make your journey exceptional.
            Let's embark on this adventure together!
          </p>
        </div>
      </div>
    </div>
  );
};

const NewAboutComponent = MaxWidthHocWrapper(AboutUs);

export default NewAboutComponent;
