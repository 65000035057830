import React from "react";
import { MaxWidthHocWrapper } from "../components/MaxWidthHocWrapper.js";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4 sm:p-6 md:p-8 bg-gradient-to-b from-white to-gray-50 shadow-xl rounded-lg">
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-800 mb-6 sm:mb-8 text-center font-libreCaslonDisplay">
        Privacy Policy
      </h1>
      <div className="space-y-6 sm:space-y-8 text-gray-700 leading-relaxed">
        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            Introduction
          </h2>
          <p className="text-base sm:text-lg">
            At Trip Closure, we value your privacy and are committed to
            protecting your personal data. This Privacy Policy outlines how we
            collect, use, and protect your information when you use our
            services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            Information We Collect
          </h2>
          <p className="text-base sm:text-lg">
            We may collect personal information that you provide to us directly,
            such as your name, email address, phone number, and payment
            information when you book a service with us. We also collect data
            about your travel preferences and history.
          </p>
        </section>

        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            How We Use Your Information
          </h2>
          <ul className="list-disc pl-5 sm:pl-6 text-base sm:text-lg space-y-2">
            <li>
              To process your bookings and provide the services you request.
            </li>
            <li>
              To personalize your experience and offer tailored recommendations.
            </li>
            <li>
              To communicate with you regarding your bookings, inquiries, and
              other customer service needs.
            </li>
            <li>
              To improve our services and enhance your overall experience.
            </li>
            <li>To comply with legal obligations and protect against fraud.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            Information Sharing and Disclosure
          </h2>
          <p className="text-base sm:text-lg">
            We do not sell your personal information to third parties. We may
            share your information with trusted partners, such as airlines,
            hotels, and insurance providers, to fulfill your bookings and
            provide related services. We may also share information as required
            by law or to protect our rights.
          </p>
        </section>

        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            Data Security
          </h2>
          <p className="text-base sm:text-lg">
            We implement appropriate security measures to protect your personal
            information from unauthorized access, alteration, and disclosure.
            However, please be aware that no method of transmission over the
            internet or electronic storage is completely secure.
          </p>
        </section>

        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            Your Rights
          </h2>
          <p className="text-base sm:text-lg">
            You have the right to access, correct, or delete your personal
            information that we hold. You may also opt out of receiving
            promotional communications from us at any time.
          </p>
        </section>

        <section>
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-3 sm:mb-4">
            Changes to This Policy
          </h2>
          <p className="text-base sm:text-lg">
            We may update this Privacy Policy from time to time. We encourage
            you to review this page periodically to stay informed about how we
            are protecting your information.
          </p>
        </section>
      </div>
    </div>
  );
};

const PrivacyPolicyComponent = MaxWidthHocWrapper(PrivacyPolicy);

export default PrivacyPolicyComponent;
